import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-4 bg-white border rounded-xl shadow">
        <p className="text-gray-700">
          <strong>Total:</strong> {payload[0]?.payload?.value}
        </p>
      </div>
    );
  }
  return null;
};

const HistogramBar = ({ data, title, dataKey, revenue }) => {

    return (
        <div className="p-6 bg-white border border-white rounded-xl shadow">
        <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-left mt-[10px] mb-[20px]" style={{ marginLeft: "25px", flexWrap: "wrap", marginRight: "10px"}}>
            {title}
            </h3>
        </div>

        <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data} barSize={45}>
            <XAxis
                dataKey="name"
                axisLine={false}
                tickLine={false}
                tick={{ fill: "#6b7280", fontSize: 12 }}
            />
            <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: "#6b7280", fontSize: 12 }}
                tickFormatter={(value, index) =>
                index === 0 ? "" : `${value}${revenue ? "€" : ""}`
                }
            />
            <Tooltip
                cursor={{ fill: "rgba(126, 126, 126, 0.1)" }}
                content={<CustomTooltip />}
            />
            <Bar dataKey={dataKey} fill="#FFD699" radius={[15, 15, 15, 15]} />
            </BarChart>
        </ResponsiveContainer>
        </div>
    );
};

export default HistogramBar;
