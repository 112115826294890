import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import "../../styles/dashboard/charts.css"; 

const COLORS = ["#FFD699", "#90CAF9", "#F08300", "#D1D5DB"]; // Custom colors for the chart

// Function to darken a color
const darkenColor = (hex, amount) => {
  let usePound = false;
  if (hex[0] === "#") {
    hex = hex.slice(1);
    usePound = true;
  }
  const num = parseInt(hex, 16);
  let r = (num >> 16) + amount;
  let g = ((num >> 8) & 0x00ff) + amount;
  let b = (num & 0x0000ff) + amount;

  r = Math.min(255, Math.max(0, r));
  g = Math.min(255, Math.max(0, g));
  b = Math.min(255, Math.max(0, b));

  return (usePound ? "#" : "") + ((r << 16) | (g << 8) | b).toString(16).padStart(6, "0");
};

const DonutChart = ({ data, title, phoneView, needTransform }) => {
  const windowSize = window.innerWidth;

  let transformedData

  if (needTransform) {
    transformedData = Object.entries(data["memberships"]).map(
      ([key, value]) => ({
        name: key,
        value: value.total, // Use the `total` field for the value
      })
    );
  } else {
    transformedData = data
  }

  // Custom label rendering
  const renderCustomLabel = (entry) => {
    const { value, cx, cy, midAngle, innerRadius, outerRadius, index } = entry;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 10; // Move the label further outside the chart
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={darkenColor(COLORS[index % COLORS.length], -30)} // Darken the slice color
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        style={{
          fontSize: "14px",
          fontWeight: "bold",
          fontFamily: "'Roboto', sans-serif",
        }}
      >
        {value}
      </text>
    );
  };

  // Custom legend content
  const CustomLegendContent = (props) => {
    const { payload } = props;

    return (
      <div className={`flex ${windowSize < 1000 || props.phoneView ? "flex-row flex-wrap" : "flex-col"} justify-center gap-4 ml-[20px]`}>
        {payload.map((entry, index) => (
          <div key={index} className="flex items-center">
            <div
              style={{
                backgroundColor: entry.color,
                width: "12px", // Size of the circle
                height: "12px", // Size of the circle
                borderRadius: "50%", // Make it a circle
                marginRight: "5px",
              }}
            ></div>
            <span>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="py-6 px-3 bg-white border rounded-xl shadow flex">
      <div className="flex-grow">
        <h3 className="px-3 text-lg font-semibold text-left ml-[25px] mt-[10px] mb-[35px]">{title}</h3>
        <ResponsiveContainer width="100%" height={300} >
          <PieChart>
            <Pie
              data={transformedData}
              cx={windowSize < 950 ? "52%" : "48%"} // Shift the pie chart to the left to create space for the legend
              cy="50%"
              innerRadius="40%"
              outerRadius="80%"
              fill="#8884d8"
              paddingAngle={2}
              dataKey="value"
              cornerRadius={3} // Add rounded corners to slices
              labelLine={false}
              label={renderCustomLabel} // Use the custom label
            >
              {transformedData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            { windowSize < 1000 || phoneView ? 
              <Legend
              content={<CustomLegendContent phoneView={phoneView}/>} // Use custom content for the legend
              align="center"
              verticalAlign="bottom"
              height={36}
              wrapperStyle={{
                marginTop: "-30px",
                marginRight: "1.5rem",
                fontSize: "12px",
              }}
            /> : 
            <Legend
              content={<CustomLegendContent />} // Use custom content for the legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              height={36}
              wrapperStyle={{
                marginTop: "-30px",
                marginRight: "1.5rem",
                fontSize: "12px",
              }}
            />
            }
            
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default DonutChart;
