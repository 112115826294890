import React from "react";
import { FaDollarSign, FaUsers, FaUser } from "react-icons/fa";
import { MdLocalDrink } from "react-icons/md";

const DataBlock = ({ title, value, type }) => {
  const isPhone = window.innerWidth <= 768;

  // Function to format numbers with a space for values over 999
  const formatValue = (value, type) => {
    // Ensure the value is a float for 'money' or 'subscription'
    let formattedValue = parseFloat(value);
  
    if (type === "money" || type === "subscription") {
      // Convert to float and then format as needed
      formattedValue = formattedValue.toFixed(2); // Ensure it's always 2 decimal points (e.g., 1,000.00)
    }
  
    // Convert to string for manipulation
    let valueStr = formattedValue.toString();
  
    // If the number is greater than 999, add a space between the first digit and the rest
    if (formattedValue > 999) {
      valueStr = valueStr.charAt(0) + ' ' + valueStr.slice(1); // Adds space between the first digit and the rest
    }
  
    // Append the euro symbol for 'money' or 'subscription' types
    if (type === "money" || type === "subscription") {
      valueStr += "€";
    }
  
    return valueStr;
  };
  

  // Determine the icon and background color based on the type
  let icon, bgColor;
  if (type === "money") {
    icon = <FaDollarSign color="black" size={20} />;
    bgColor = "bg-green-100";
  } else if (type === "subscription") {
    icon = <FaUsers color="black" size={20} />;
    bgColor = "bg-yellow-100";
  } else if (type === "drink") {
    icon = <MdLocalDrink color="black" size={20} />;
    bgColor = "bg-blue-100";
  } else if (type === "user") {
    icon = <FaUser color="black" size={20} />;
    bgColor = "bg-gray-100";
  }

  return (
    <div className="px-6 py-9 bg-white border-transparent rounded-xl shadow flex flex-col items-center justify-center">
      {/* Left Column: Icon in a circle */}
      {/* Right Column: Value and Title aligned left */}
      <div
        className={`w-12 h-12 ${bgColor} flex items-center justify-center rounded-full`}
      >
        {icon}
      </div>
      <div className={`text-black mt-4 ${isPhone ? "text-xl" : "text-4xl"} font-semibold`}>
        {formatValue(value, type)}
      </div>
      <div className="text-gray-500 text-sm mt-[3px]">{title}</div>
    </div>
  );
};

export default DataBlock;
