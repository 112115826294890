import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const MenuBar = ({ menuItems }) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="flex items-center mb-4 mt-4 px-1 py-1 w-fit bg-customOrange rounded-xl">
      {menuItems.map((item) => (
        <button
          key={item.name}
          onClick={() => navigate(item.route)}
          className={`px-4 py-2 text-sm font-medium transition-all duration-200 ${
            location.pathname === item.route
              ? "text-white font-semibold bg-customOrange-dark rounded-xl"
              : "text-gray-400 hover:text-white"
          }`}
        >
          {item.name}
        </button>
      ))}
    </div>
  );
};

export default MenuBar;
