import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { fetchGymInfo, fetchProductInfo } from './utils';


export function fetchAllMachinesData() {
    return (dispatch) => {
        const currentDate = new Date();
        const pastWeekFromNowTimeStamp = currentDate.getTime() - (7 * 24 * 3600 * 1000); // one week in seconds

        firebase.firestore()
            .collection("machines")
            .where("showInApp", "==", true)
            .get()
            .then(async (machineQuerySnapshot) => {
                let promises = machineQuerySnapshot.docs.map(async (machineDoc) => {
                    const machineData = machineDoc.data();
                    const machineInfo = {
                        id: machineDoc.id,
                        name: machineData.name,
                        hasSentEmail: machineData.hasSentEmail,
                        isActive: machineData.isActive,
                        type: machineData.type,
                        size: machineData.size
                    };

                    // Fetch complaints
                    const complaintsSnapshot = await firebase.firestore()
                        .collection("userFeedbacks")
                        .where("machineId", "==", machineInfo.id)
                        .where("timeStamp", ">=", pastWeekFromNowTimeStamp)
                        .where("rating", "<=", 3)
                        .orderBy("timeStamp")
                        .get();

                    const complaintsList = complaintsSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                        nameMachine: machineData.name,
                    }));

                    const complaints = { complaints: complaintsList };

                    // Fetch gym and owner information
                    const userData = await fetchGymInfo(machineInfo, complaints, machineData.gymId, machineData.ownerId);
                    return userData;
                });

                // Wait for all machine details to be fetched
                Promise.all(promises)
                    .then((updatedAllMachinesDataArray) => {
                        const sortedMachines = [...updatedAllMachinesDataArray].sort((a, b) =>
                            a.nameGym.localeCompare(b.nameGym)
                        );
                        dispatch({
                            type: "FETCH_ALL_MACHINES_INFO",
                            allMachinesData: sortedMachines,
                        });
                    })
                    .catch((error) => {
                        dispatch({ type: "ERROR_FETCHING_ALL_MACHINES_INFO", error: error });
                    });
            })
            .catch((error) => {
                dispatch({ type: "ERROR_FETCHING_ALL_MACHINES_INFO", error: error });
            });
    };
}



export function fetchOwnerMachinesInfo(ownerId) {
    // fetch the info of all machines that belongs to a user
    return (dispatch) => {
        const productRef = firebase.firestore().collection("machineProducts");

        // Use a Firestore query to filter machines by "ownerId"
        firebase.firestore()
            .collection("machines")
            .where("ownerId", "==", ownerId) // Filter machines by "ownerId"
            .get()
            .then((machineQuerySnapshot) => {
                const promises = [];

                machineQuerySnapshot.forEach((machineDoc) => {
                    const machineData = machineDoc.data()
                    const machineId = machineDoc.id;

                    // Now, you have the machineId, and you can call your existing method to fetch machine details.
                    const machineDetailsPromise = fetchMachineMenu(machineId, productRef, machineData);

                    promises.push(machineDetailsPromise);
                });

                // We need to wait until all machine details have been fetched.
                Promise.all(promises)
                .then((updatedMachineArray) => {
                    dispatch({ type: 'FETCH_MACHINE_INFO', machineProducts: updatedMachineArray });
                })
                .catch((error) => {
                    dispatch({ type: 'ERROR_FETCHING_MACHINE_INFO', error: error });
                });
            })
            .catch((error) => {
                dispatch({ type: 'ERROR_FETCHING_MACHINE_INFO', error: error });
            });
    };
}   

// Separate method to fetch machine details using machineId
function fetchMachineMenu(machineId, productRef, machineData) {
    return new Promise((resolve, reject) => {
        firebase.firestore()
            .collection("machines")
            .doc(machineId)
            .collection("items")
            .orderBy("order")
            .get()
            .then((snapshot) => {
                const promises = [];

                snapshot.forEach((doc) => {
                    const data = doc.data();

                    let itemInfo = {id: data.flavorId, order: data.order, familyId: data["familyId"]}

                    // We need to retrieve the information about each product
                    const familyProductRef = productRef.doc(data["familyId"]);
                    const flavorDocId = itemInfo["id"];
                    const productsPromises = fetchProductInfo(itemInfo, familyProductRef, flavorDocId);

                    promises.push(productsPromises);
                });

                // We need to wait until the description data has been fetched
                Promise.all(promises)
                .then((updatedMachineArray) => {
                    const machineDetails = {
                        data: machineData, 
                        items: updatedMachineArray
                    }
                    resolve(machineDetails);
                })
                .catch((error) => {
                    reject(error);
                });
            })
            .catch((error) => {
                reject(error);
            });
    });
}


export function setIsLoadingMenu(callback) {
    return (dispatch) => {
        dispatch({ type: 'IS_LOADING_MENU' });
        if (typeof callback === 'function') {
            callback();
        }
    };
}

export function changeIsMachineActive(machineId, isActive) {
    return (dispatch) => {
        firebase.firestore().collection("machines").doc(machineId).update({
            isActive: isActive
        }).then(() => {
            dispatch({ type: 'CHANGE_IS_MACHINE_ACTIVE'});
        }).catch((error) => {
            console.log(error)
            dispatch({ type: 'ERROR_CHANGING_IS_MACHINE_ACTIVE', error: error });
        });
    };
}