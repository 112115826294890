
const initState = {
    gyms: null,
    gymsFetched: false, 
    gymsData: {}, 
    gymDataFetched: false, 
    setMachineIdGymSuccess: false, 
    machineIdGym: null, 
    gymName: null,
    selectedGymsAnalysis: [], 
    fetchedGymsFinanceNumber: 0,
    fetchedGymsMembershipNumber: 0 
}

const gymReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_GYMS_INFO':
            return {
                ...state,
                gyms: action.gyms, 
                gymsFetched: true
            };
        case 'ERROR_FETCHING_GYMS_INFO': 
            return {
                ...state, 
                gymsFetched: false
            }
        case 'START_FETCHING_GYM_DATA': 
            return {
                ...state, 
                gymDataFetched: false
            }
        case 'FETCH_GYM_DATA_SUCCESS': 
            state.gymsData[action.machineId] = action.data
            state.gymsData[action.machineId]["name"] = action.nameGym
            return {
                ...state, 
                gymDataFetched: true, 
                fetchedGymsFinanceNumber: state.fetchedGymsFinanceNumber + 1, 
            }
        case 'SET_MACHINE_ID_GYM_SUCCESS': 
            return {
                ...state, 
                setMachineIdGymSuccess: true, 
                machineIdGym: action.machineId
            }
        case 'RESET_MACHINE_ID_GYM_SUCCESS': 
            return {
                ...state, 
                setMachineIdGymSuccess: false
            }
        case 'SET_GYM_NAME_SUCCESS': 
            return {
                ...state, 
                gymName: action.gymName
            }
        case 'RESET_GYM_NAME_SUCCESS': 
            return {
                ...state, 
                gymName: null
            }
        case 'SET_SELECTED_GYMS_ANALYSIS': 
            return {
                ...state, 
                selectedGymsAnalysis: action.selectedGymsAnalysis
            }
        case 'SET_GYM_DATA_READY': 
            return {
                ...state, 
                gymDataFetched: true
            }
        default:
            return state
    }
}

export default gymReducer