import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../styles/dashboard/dashboardPage.css";
import { Table, TableBody, TableCell, TableHeadCell, TableHeader, TableRow } from "../../utils/dashboard/table";
import { reload } from "../../store/actions/reload";
import { fetchAllMachinesData } from "../../store/actions/machine";
import { RotatingLines } from "react-loader-spinner";
import MenuBar from "src/utils/dashboard/menuBar";

const MachinesPage = (props) => {
  const { reload } = props;
  const userId = props.userId;
  const [popoverData, setPopoverData] = useState(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: "timeStamp", direction: "descending" }); // Default sorting state
  const isPhone = window.innerWidth <= 768;

  useEffect(() => {
    reload(userId);
  }, [reload, userId]);

  useEffect(() => {
    if (!props.allMachinesDataFetched) {
      props.fetchAllMachinesData();
    }
  }, [props.allMachinesDataFetched]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      reload(userId);
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, [userId, reload]);

  const handlePopover = (event, machine) => {
    event.stopPropagation();
    setPopoverData(machine);
    setIsPopoverVisible((prev) => (popoverData?.id === machine.id ? !prev : true));
  };

  const closePopover = () => {
    setIsPopoverVisible(false);
  };

  // Handle sorting logic
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const renderSortIndicator = (key) => {
    const isActive = sortConfig.key === key;
    const ascending = isActive && sortConfig.direction === "ascending";
    const descending = isActive && sortConfig.direction === "descending";

    return (
      <span className="ml-2 text-gray-500">
        <span className={`${descending ? "text-black" : ""}`}>↑</span>
        <span className={`${ascending ? "text-black" : ""}`}>↓</span>
      </span>
    );
  };

  const menuItems = [
    { name: "Machines", route: "/management/machines" },
    { name: "Plaintes", route: "/management/plaintes" }
  ];

  // Extract and concatenate complaints from allMachinesData
  let allComplaints = props.allMachinesData?.reduce((acc, machine) => {
    if (machine.complaints && Array.isArray(machine.complaints)) {
      return acc.concat(
        machine.complaints.map((complaint) => ({
          ...complaint,
          nameMachine: machine.name,
        }))
      );
    }
    return acc;
  }, []) || [];

  // Sort complaints based on sortConfig
  allComplaints = allComplaints.sort((a, b) => {
    const order = sortConfig.direction === "ascending" ? 1 : -1;
    if (sortConfig.key === "timeStamp") {
      return (a.timeStamp - b.timeStamp) * order; // Numeric comparison for timestamps
    }
    if (a[sortConfig.key] < b[sortConfig.key]) return -1 * order;
    if (a[sortConfig.key] > b[sortConfig.key]) return 1 * order;
    return 0;
  });

  const PopoverContent = () => (
      <div
        className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30"
        onClick={closePopover}
      >
        <div
          className="bg-white rounded-lg shadow-lg p-6"
          style={{
            width: "min(70%, 700px)",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
          onClick={(e) => e.stopPropagation()} // Prevent click from closing the popover
        >
          <p className="mb-2">
            <span className="font-semibold text-left">Nom de salle:</span> {popoverData.nameMachine}
          </p>
          <p className="mb-2">
            <span className="font-semibold text-left">Machine ID:</span> {popoverData.machineId}
          </p>
          <p className="mb-4">
            <span className="font-semibold">Nom utilisateur:</span> {popoverData.firstName} {popoverData.lastName}
          </p>
          <p className="mb-4">
            <span className="font-semibold">Email utilisateur:</span> {popoverData.email}
          </p>
          <p className="mb-4">
            <span className="font-semibold">Date:</span> {popoverData.date}
          </p>
          <p className="mb-4">
            <span className="font-semibold">Dernière boisson:</span> {popoverData.lastDrinkMachine}
          </p>
          <p className="mb-4">
            <span className="font-semibold">Type plainte:</span> {popoverData.complaintContent}
          </p>
  
        </div>
      </div>
    );

  if (props.allMachinesDataFetched) {
    return (
      <div
        className="flex flex-col h-screen w-full overflow-hidden p-6"
        style={{ paddingLeft: "4%", paddingRight: "4%", marginTop: "20px", marginBottom: "20px" }}
      >
        <div className="flex-grow overflow-auto w-full p-8">
          {/* <h3 className="mt-1.5 text-2xl font-bold mb-5 text-left">Plaintes</h3> */}
          <MenuBar menuItems={menuItems} />
          <div className="p-4 flex-grow overflow-auto bg-white border border-transparent rounded-xl shadow" style={{ marginBottom: "20px" }}>
            <Table className="w-full">
              <TableHeader>
                <TableRow className="hover:bg-transparent">
                  <TableHeadCell onClick={() => handleSort("timeStamp")} className="flex-1 cursor-pointer px-4 py-4 text-center text-sm font-medium text-gray-500">
                    Date {renderSortIndicator("timeStamp")}
                  </TableHeadCell>
                  <TableHeadCell className="flex-1  px-4 py-4 text-center text-sm font-medium text-gray-500">Nom salle</TableHeadCell>
                  <TableHeadCell className="flex-1  px-4 py-4 text-center text-sm font-medium text-gray-500">Contenu</TableHeadCell>
                  {!isPhone && (
                    <TableHeadCell className="flex-1  px-4 py-4 text-center text-sm font-medium text-gray-500">Dernière boisson</TableHeadCell>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {allComplaints.map((complaint, index) => (
                  <TableRow className="table-row-hover" key={index} onClick={(e) => handlePopover(e, complaint)}>
                    <TableCell className="flex-1 px-4 py-4 text-center text-sm text-gray-600">
                      {new Date(complaint.timeStamp).toLocaleString()} {/* Convert timestamp to readable date */}
                    </TableCell>
                    <TableCell>{complaint.nameMachine}</TableCell>
                    <TableCell>{complaint.complaintContent}</TableCell>
                    {!isPhone && <TableCell>{complaint.lastDrinkMachine}</TableCell>}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
        {isPopoverVisible && popoverData && <PopoverContent />}
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen space-y-6">
        <RotatingLines strokeColor="#f08300" height={30} width={30} visible={true} animationDuration="1.25" />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  userId: state.userState.userId,
  allMachinesData: state.machineState.allMachinesData,
  allMachinesDataFetched: state.machineState.allMachinesDataFetched,
});

const mapDispatchProps = (dispatch) => {
  return {
    reload: (userId) => {
      dispatch(reload(userId));
    },
    fetchAllMachinesData: () => {
      dispatch(fetchAllMachinesData());
    },
  };
};

export default connect(mapStateToProps, mapDispatchProps)(MachinesPage);
