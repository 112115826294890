import React from "react";
import menAvatar from "../../assets/images/men_avatar.png"; // Import male avatar
import womenAvatar from "../../assets/images/women_avatar.png"; // Import female avatar

const LastCustomersBox = ({ customers }) => (
  <div className="p-4 bg-white border border-white rounded-xl shadow-md h-full">
    <h3 className="text-lg font-semibold ml-[25px] mt-[10px] mb-[35px]">5 meilleurs clients</h3>
    <ul className="space-y-2">
      {customers.map((customer, index) => (
        <li
          key={index}
          className="flex items-center justify-between hover:bg-gray-200 border-transparent rounded-xl px-4 py-2 hover:cursor-pointer"
        >
          {/* Avatar */}
          <img
            src={customer.gender === "male" ? menAvatar : womenAvatar}
            alt={`${customer.name}'s avatar`}
            className="w-10 h-10 rounded-full object-cover flex-shrink-0"
          />
          {/* Text Block */}
          <div className="flex flex-grow flex-col justify-center items-center px-4">
            <p className="text-sm font-medium text-gray-700 text-center">{customer.firstName} {customer.lastName}</p>
            <p className="text-xs text-gray-500 text-center">{customer.email}</p>
          </div>
          {/* Amount Spent */}
          <div className="text-sm font-semibold text-gray-700 flex-shrink-0">
            {customer.totalSpent.toFixed(2)}€
          </div>
        </li>
      ))}
    </ul>
  </div>
);

export default LastCustomersBox;
