import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


export function fetchAllGymsData() {
    // fetch the info of all machines and the info the clientId who owns the machine
    return (dispatch) => {
        const gyms = [{'id': 0, 'name': 'Nouvelle salle'}]

        // Use a Firestore query to filter machines by "clientId"
        firebase.firestore()
            .collection("gyms")
            .orderBy("name")
            .get()
            .then((gymQuerySnapshot) => {
                gymQuerySnapshot.forEach((gym) => {
                    const data = gym.data()
                    const id = gym.id
                    let gymData = {id, ...data}
                    gyms.push(gymData)
                })

                dispatch({ type: "FETCH_GYMS_INFO", gyms: gyms });
            })
            .catch((error) => {
                dispatch({ type: 'ERROR_FETCHING_GYMS_INFO', error: error });
            });
    };
}

export function fetchGymData(machineId, nameGym) {
        return async (dispatch) => {
            dispatch({ type: "START_FETCHING_GYM_DATA" });
    
            const machinesRef = firebase.firestore().collection("machines");
    
            try {
                // Fetch previous months' financial data
                const financialsRef = machinesRef.doc(machineId).collection("financials");
                const financialsSnapshot = await financialsRef.get();
    
                const allMonthsData = {};
    
                financialsSnapshot.forEach((doc) => {
                    allMonthsData[doc.id] = doc.data();
                });
    
                dispatch({
                    type: "FETCH_GYM_DATA_SUCCESS",
                    data: allMonthsData,
                    machineId: machineId,
                    nameGym: nameGym,
                });
            } catch (error) {
                console.error("Error fetching gym data:", error);
                dispatch({ type: "FETCH_DASHBOARD_DATA_FAILURE", error });
            }
        };
    }

export const setMachineIdGym = (machineId) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_MACHINE_ID_GYM_SUCCESS',
            machineId: machineId
        })
    }
}

export const resetMachineIdGym = () => {
    return (dispatch) => {
        dispatch({
            type: 'RESET_MACHINE_ID_GYM_SUCCESS',
        })
    }
}

export const setGymName = (gymName) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_GYM_NAME_SUCCESS',
            gymName: gymName
        })
    }
}

export const resetMGymName = () => {
    return (dispatch) => {
        dispatch({
            type: 'RESET_GYM_NAME_SUCCESS',
        })
    }
}


export const setSelectedGymsAnalysis = (selectedGymsAnalysis) => {
    return (dispatch) => {
        dispatch({
            type: 'SET_SELECTED_GYMS_ANALYSIS',
            selectedGymsAnalysis: selectedGymsAnalysis
        })
    }
}

export const setGymDataReady = () => {
    return (dispatch) => {
        dispatch({
            type: 'SET_GYM_DATA_READY',
        })
    }
}

