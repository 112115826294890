import React, { useEffect, useState } from "react";
import "../styles/navbar.css";
import Logo from "../assets/images/Fiilin_logo.png";
import ProfileImage from "../assets/images/userProfileImage.png"; // User profile image
import { FaHome } from "react-icons/fa";
import { MdSpaceDashboard, MdAutoGraph } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { FaTools } from "react-icons/fa";

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isPhone = window.innerWidth <= 768;

  const [selectedPage, setSelectedPage] = useState("/");

  useEffect(() => {
    setSelectedPage(location.pathname);
  }, [location.pathname]);

  if (isPhone) {
    return (
      <nav className="navbar-fixed-phone bg-customOrange-light">
        <div className="navbar-container-phone">
          {/* Logo and Mascott */}
          <div className="flex items-center">
            <button onClick={() => navigate("/")} className="brand-loggo-phone">
              {/* <img src={Mascott} alt="Mascott" className="mascott-image ml-2" /> */}
              <img src={Logo} alt="logo" className="logo-image-phone" />
            </button>
          </div>
  
          {/* Menu Items */}
          <div className="navbar-text-container-phone ml-[5px] flex items-center gap-[2px]">
            <button
              onClick={() => navigate("/")}
              className={`navbar-text-content-phone ${
                selectedPage.startsWith("/management") ? "navbar-text-content-selected" : ""
              }`}
            >
              {/* <FaHome className="mr-[5px]" /> */}
              Pilotage
            </button>
            <button
              onClick={() => navigate("/finance/dashboard")}
              className={`navbar-text-content-phone ${
                selectedPage.startsWith("/finance")
                  ? "navbar-text-content-selected"
                  : ""
              }`}
            >
              {/* <MdSpaceDashboard className="mr-[5px]" /> */}
              Finance
            </button>
            <button
              onClick={() => navigate("/analysis")}
              className={`navbar-text-content-phone ${
                selectedPage.startsWith("/analysis")
                  ? "navbar-text-content-selected"
                  : ""
              }`}
            >
              {/* <MdAutoGraph className="mr-[5px]" /> */}
              Analyse
            </button>
            <button
              onClick={() => navigate("/analysis")}
              className={`navbar-text-content-phone ${
                selectedPage.startsWith("/analysis")
                  ? "navbar-text-content-selected"
                  : ""
              }`}
            >
              {/* <MdAutoGraph className="mr-[5px]" /> */}
              Maintenance
            </button>

          </div>
  
          {/* Profile Icon */}
          <div className="icon-profile">
            <img
              src={ProfileImage}
              alt="User Profile"
              className="profile-image-phone rounded-full border border-white shadow object-cover p-1"
            />
          </div>
        </div>
      </nav>
    );
  } else {
    return (
      <nav className="navbar-fixed bg-customOrange-light">
        <div className="navbar-container">
          {/* Logo and Mascott */}
          <div className="flex items-center">
            <button onClick={() => navigate("/")} className="brand-loggo">
              {/* <img src={Mascott} alt="Mascott" className="mascott-image ml-2" /> */}
              <img src={Logo} alt="logo" className="logo-image" />
            </button>
          </div>
  
          {/* Menu Items */}
          <div className="navbar-text-container flex items-center gap-4">
            <button
              onClick={() => navigate("/")}
              className={`navbar-text-content ${
                selectedPage.startsWith("/management") ? "navbar-text-content-selected" : ""
              }`}
            >
              <FaHome className="mr-2" />
              Pilotage
            </button>
            <button
              onClick={() => navigate("/finance/dashboard")}
              className={`navbar-text-content ${
                selectedPage.startsWith("/finance")
                  ? "navbar-text-content-selected"
                  : ""
              }`}
            >
              <MdSpaceDashboard className="mr-2" />
              Finance
            </button>
            <button
              onClick={() => navigate("/analysis")}
              className={`navbar-text-content ${
                selectedPage.startsWith("/analysis")
                  ? "navbar-text-content-selected"
                  : ""
              }`}
            >
              <MdAutoGraph className="mr-2" />
              Analyse
            </button>
            <button
              onClick={() => navigate("/maintenance")}
              className={`navbar-text-content ${
                selectedPage.startsWith("/maintenance")
                  ? "navbar-text-content-selected"
                  : ""
              }`}
            >
              <FaTools className="mr-2" />
              Maintenance
            </button>
          </div>
  
          {/* Profile Icon */}
          <div className="mr-3 icon-profile ml-auto">
            <img
              src={ProfileImage}
              alt="User Profile"
              className="profile-image rounded-full border border-white shadow object-cover p-1"
            />
          </div>
        </div>
      </nav>
    );
  }
  
};

export default NavBar;
