import React from 'react';
import { RotatingLines } from 'react-loader-spinner'; // Assuming you have this package installed
import Logo from "../assets/images/Fiilin_logo.png";

const WaitingPage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="flex flex-col items-center space-y-6">
        {/* Logo */}
        <img src={Logo} alt="logo" className="w-32 h-auto" />
        {/* Spinner */}
        <RotatingLines 
          strokeColor="#f08300" 
          height={30} 
          width={30} 
          visible={true} 
          animationDuration="1.25" 
        />
      </div>
    </div>
  );
};

export default WaitingPage;