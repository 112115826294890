export const PlaceholderBlock = ({ title }) => (
    <div className="animate-pulse p-4 bg-gray-200 rounded-md shadow">
      <div className="h-6 bg-gray-300 mb-2 rounded"></div>
      <div className="h-10 bg-gray-300 rounded"></div>
    </div>
);

export const PlaceholderGraph = () => (
    <div className="animate-pulse h-full bg-gray-200 rounded-md shadow"></div>
);

export const PlaceholderTable = () => (
    <div className="animate-pulse p-4 bg-gray-200 rounded-md shadow h-48"></div>
);

