import React, { useState, useRef, useEffect } from "react";
import { HiChevronUp, HiChevronDown } from "react-icons/hi2";
import { IoMdFitness } from "react-icons/io";
import { connect } from "react-redux";
import { resetMGymName, setGymName } from "src/store/actions/gyms";


// this component is for the gym selector
const CustomSelect = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedGymIndex, setSelectedGymIndex] = useState(0)
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const menuRef = useRef(null);


  // Handle opening/closing the dropdown
  const toggleDropdown = () => setIsOpen(!isOpen);
  const closeDropdown = () => setIsOpen(false);

  // Handle outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle keyboard navigation
  const handleKeyDown = (event) => {
    if (!isOpen) return;

    if (event.key === "ArrowDown") {
      setHighlightedIndex((prevIndex) =>
        prevIndex < props.machines.length - 1 ? prevIndex + 1 : 0
      );
    } else if (event.key === "ArrowUp") {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : props.machines.length - 1
      );
    } else if (event.key === "Enter") {
      event.preventDefault();
      props.setGymName(props.machines[highlightedIndex].nameGym);
      setSelectedGymIndex(highlightedIndex)

      if (props.machines[highlightedIndex].nameGym !== props.gymName) {
        props.onSelect(props.machines[highlightedIndex].id);
      }
      closeDropdown();
    }
  };

  // Handle option click
  const handleOptionClick = (machine, index) => {
    // we want to fetch the data or update the variables only if we select a new gym
    if (machine.nameGym !== props.gymName) {
        props.setGymName(machine.nameGym);
        setHighlightedIndex(index);
        setSelectedGymIndex(index)
        props.onSelect(machine.id, machine.nameGym);
    }
    closeDropdown();
  };

  return (
    <div
      className="relative w-64 border border-white rounded-full border-[0.5px]"
      ref={menuRef}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {/* Trigger Button */}
      <button
        onClick={toggleDropdown}
        className={`w-full px-5 py-3 bg-white border border-[0.5px] border-transparent rounded-full text-sm shadow-sm focus:outline-none flex items-center justify-between`}
        >
        {/* Left side: Icon and Text */}
        <div className="flex items-center">
            <IoMdFitness className="mr-2 text-lg" /> {/* Icon with margin and size */}
            <span>{props.gymName}</span> {/* Text */}
        </div>
        {/* Right side: Chevron */}
        <span>
            {isOpen ? (
            <HiChevronUp className="h-5 w-5 text-gray-500 inline" />
            ) : (
            <HiChevronDown className="h-5 w-5 text-gray-500 inline" />
            )}
        </span>
        </button>

      {/* Dropdown Menu */}
      {isOpen && (
        <ul className="absolute z-10 w-full mt-2 bg-white border border-gray-200 rounded-xl shadow-lg max-h-48 overflow-y-auto px-2 text-sm">
          {props.machines.map((machine, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(machine, index)}
              onMouseEnter={() => setHighlightedIndex(index)}
              className={`cursor-pointer my-1 px-4 py-3 space-y-2 
                ${selectedGymIndex === index ? "font-semibold" : "font-medium"}
                ${ highlightedIndex === index
                  ? "bg-[#FFD699] text-black font-semibold rounded-xl"
                  : "hover:bg-[#FFD699] hover:rounded-xl"
              } ` }
            >
              {machine.nameGym}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  gymName: state.gymState.gymName
});

const mapDispatchProps = (dispatch) => ({
  setGymName: (gymName) => dispatch(setGymName(gymName)), 
  resetMGymName: () => dispatch(resetMGymName()),
});

export default connect(mapStateToProps, mapDispatchProps)(CustomSelect);