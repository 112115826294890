// this is for the design of the size feature inside the table of presentation of all the machines
export const getSizeStyle = (size) => {
    let bgColor = "";
    let textColor = "";
  
    switch (size) {
      case "<500":
        bgColor = "#FFD580"; // Lighter Orange
        textColor = "#fff";
        break;
      case "<1000":
        bgColor = "#FF9A3D"; // Clearer Medium Orange
        textColor = "#fff";
        break;
      case "<1500":
        bgColor = "#FF7F32"; // Clearer Darker Orange
        textColor = "#fff";
        break;
      case "<2000":
        bgColor = "#B67CC7"; // Lighter Purple-leaning
        textColor = "#fff";
        break;
      case "<2500":
        bgColor = "#A35B94"; // Clearer Purple
        textColor = "#fff";
        break;
      case ">2500":
        bgColor = "#9B478D"; // Clearer Dark Purple
        textColor = "#fff";
        break;
      default:
        bgColor = "transparent";
        textColor = "inherit";
    }
  
    return {
      display: "inline-block",
      backgroundColor: bgColor,
      color: textColor,
      borderRadius: "12px",
      padding: "6px 14px", // Padding around text
      maxWidth: "auto", // Let the background only wrap the text width
      textAlign: "center",
      whiteSpace: "nowrap", // Prevents text wrapping
      fontSize: "14px"
    };
};
  
  
  // this is for the design of the style feature inside the table of presentation of all the machines
export const getTypeStyle = (type) => {
    let bgColor = "";
    let textColor = "";
  
    switch (type) {
      case "muscu":
        bgColor = "#4A90E2"; // Blue
        textColor = "#fff";
        break;
      case "crossfit":
        bgColor = "#A2C9FF"; // Light blue
        textColor = "#fff";
        break;
      case "office":
        bgColor = "#80E27E"; // Light Green
        textColor = "#fff";
        break;
      case "general":
        bgColor = "#388E3C"; // Green
        textColor = "#fff";
        break;
      default:
        bgColor = "transparent";
        textColor = "inherit";
    }
  
    return {
      display: "inline-block",
      backgroundColor: bgColor,
      color: textColor,
      borderRadius: "12px",
      padding: "6px 14px", // Padding around text
      maxWidth: "auto", // Let the background only wrap the text width
      textAlign: "center",
      whiteSpace: "nowrap", // Prevents text wrapping
      fontSize: "14px"
    };
};