import './App.css';
import './index.css';
import NavBar from './navigation/navbar';
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Dashboard from './pages/finance/dashboard'; // Default subroute
import Reports from './pages/finance/reports'; // Subroute
import Settings from './pages/finance/settings'; // Subroute
import SignInPage from "./pages/auth/signIn";
import LoadingSpinner from './pages/loadingSpinner';

import { connect } from 'react-redux';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { firebaseConfig } from './firebase';
import { useEffect, useState } from 'react';
import { setUserId } from './store/actions/user';
import MachinesPage from './pages/management/machinesPage';
import Complaints from './pages/management/complaints';
import Maintenance from './pages/maintenance/maintenance';
import Analysis from './pages/analysis/analysis';

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

const ProtectedRoute = ({ children, auth }) => {
  if (!auth) {
    return <Navigate to="/signIn" replace />;
  }
  return children;
};


// Layout for /finance and its subroutes
const PageLayout = () => (
  <div className="App-container">
    <NavBar />
    <div className={`min-h-screen flex flex-col w-full bg-customOrange-light ${window.innerWidth < 750 ? "mt-[84px]" : "mt-[30px]"}`}>
      <div className="w-full border border-transparent rounded-lg shadow-md bg-customOrange-light">
        <Outlet /> {/* Renders the subroute content */}
      </div>
    </div>
  </div>
);

const App = ({ userId, setUserId }) => {
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const authStateChanged = (user) => {
      if (!user) {
        setLoaded(true);
        setUser(null);
        setUserId(null);
      } else {
        setUserId(user.uid);
        setUser(user);
        setTimeout(() => {
          setLoaded(true); // Set loaded to true after a 1-second delay
        }, 1000);
      }
    };

    const unsubscribe = firebase.auth().onAuthStateChanged(authStateChanged);

    return () => {
      unsubscribe();
    };
  }, [setUserId]);

  if (!loaded) {
    return <LoadingSpinner />;
  }

  return (
    <BrowserRouter>
      <div className="App bg-customOrange-light">
        <main className="content bg-customOrange-light">
          <Routes>
            {/* Sign In Route */}
            <Route index path="/signIn" element={<SignInPage />} />

            {/* Main Dashboard Route */}
            <Route
              path="/management"
              element={
                <ProtectedRoute auth={userId !== null}>
                  <PageLayout />
                </ProtectedRoute>
              }
            >
              {/* Default Redirect */}
              <Route index element={<Navigate to="/management/machines" replace />} />

              {/* Subroutes */}
              <Route path="machines" element={<MachinesPage />} />
              <Route path="plaintes" element={<Complaints />} />
            </Route>

            {/* Finance Parent Route */}
            <Route
              path="/finance"
              element={
                <ProtectedRoute auth={userId !== null}>
                  <PageLayout />
                </ProtectedRoute>
              }
            >
              {/* Default Redirect */}
              <Route index element={<Navigate to="/finance/dashboard" replace />} />

              {/* Subroutes */}
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="reports" element={<Reports />} />
              <Route path="settings" element={<Settings />} />
            </Route>
            <Route
              path="/analysis"
              element={
                <ProtectedRoute auth={userId !== null}>
                  <PageLayout />
                </ProtectedRoute>
              }
            >
              {/* Default Redirect */}
              <Route index element={<Analysis />} />

            </Route>
            {/* Maintenance */}
            <Route
              path="/maintenance"
              element={
                <ProtectedRoute auth={userId !== null}>
                  <PageLayout />
                </ProtectedRoute>
              }
            >
              {/* Default Redirect */}
              <Route index element={<Maintenance />} />

            </Route>

            {/* Fallback Route */}
            <Route path="*" element={<Navigate to="/management/" replace />} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  userId: state.userState.userId,
  validUser: state.userState.validUser,
});

const mapDispatchProps = (dispatch) => ({
  setUserId: (userId) => {
    dispatch(setUserId(userId));
  },
});

export default connect(mapStateToProps, mapDispatchProps)(App);
library.add(fas);
