
const initState = {
    tasks: [], 
    maintenanceTasksFetched: false
}

const tasksReducer = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_MAINTENANCE_TASKS_INFO':
            return {
                ...state,
                tasks: action.tasks, 
                maintenanceTasksFetched: true
            };
        case 'ERROR_FETCHING_GYMS_INFO': 
            return {
                ...state, 
                maintenanceTasksFetched: false
            }
        case 'UPDATE_MAINTENANCE_TASK': 
            return {
                ...state, 
                tasks: action.tasks
            }
        case 'ERROR_UPDATING_MAINTENANCE_TASK': 
            return {
                ...state
            }
        case 'ADD_MAINTENANCE_TASK': 
            return {
                ...state, 
                tasks: action.tasks
            }
        case 'ERROR_ADDING_MAINTENANCE_TASK': 
            return {
                ...state
            }
        default:
            return state
    }
}

export default tasksReducer