import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


export function fetchAllMaintenanceTasks() {
    // fetch the info of all machines and the info the clientId who owns the machine
    return (dispatch) => {
        let tasks = []
        // Use a Firestore query to filter machines by "clientId"
        firebase.firestore()
            .collection("maintenance")
            .orderBy("timestampCreated", "desc")
            .get()
            .then((maintenanceQuerySnapshot) => {
                maintenanceQuerySnapshot.forEach((task) => {
                    const data = task.data()
                    const id = task.id
                    let taskData = {id, ...data}
                    tasks.push(taskData)
                })

                console.log("tasks: ", tasks)
                dispatch({ type: "FETCH_MAINTENANCE_TASKS_INFO", tasks: tasks });
            })
            .catch((error) => {
                dispatch({ type: 'ERROR_FETCHING_MAINTENANCE_TASKS_INFO', error: error });
            });
    };
}

export function updateMaintenanceTask(taskId, title, description, status, type, gym, nameResponsible, emailResponsible, colorBackgroundName, tasks) {
    return (dispatch) => {
        const updatedTask = {
            id: taskId,
            title: title,
            description: description,
            status: status,
            type: type,
            gym: gym,
            responsible: {
                name: nameResponsible,
                email: emailResponsible,
            },
            colorBackgroundName: colorBackgroundName,
            done: status === "Terminé",
        };

        firebase.firestore()
            .collection("maintenance")
            .doc(taskId)
            .update(updatedTask)
            .then(() => {
                // Update the tasks array
                const updatedTasks = tasks.map((task) =>
                    task.id === taskId ? updatedTask : task
                );

                // Dispatch the updated tasks
                dispatch({ type: "UPDATE_MAINTENANCE_TASK", tasks: updatedTasks });
            })
            .catch((error) => {
                dispatch({ type: "ERROR_UPDATING_MAINTENANCE_TASK", error: error });
            });
    };
}

export function addMaintenanceTask(title, description, status, type, gym, nameResponsible, emailResponsible, colorBackgroundName, tasks) {
    return (dispatch) => {
        const newTask = {
            title: title,
            description: description,
            status: status,
            type: type,
            gym: gym,
            responsible: {
                name: nameResponsible,
                email: emailResponsible,
            },
            colorBackgroundName: colorBackgroundName,
            done: status === "Terminé",
            timestampCreated: Date.now(),
        };

        firebase.firestore()
            .collection("maintenance")
            .add(newTask)
            .then((docRef) => {
                // Add the Firestore-generated ID to the task
                const taskWithId = { id: docRef.id, ...newTask };

                // Update the tasks array immutably
                const newTasks = [...tasks, taskWithId];

                // Dispatch the updated tasks
                dispatch({ type: "ADD_MAINTENANCE_TASK", tasks: newTasks });
            })
            .catch((error) => {
                dispatch({ type: "ERROR_ADDING_MAINTENANCE_TASK", error: error });
            });
    };
}


