import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";

const CustomTooltip = ({ active, payload, label, dataKey }) => {
  if (active && payload && payload.length) {
    return (
      <div className="p-4 bg-white border rounded-xl shadow">
        <p style={{ marginBottom: "8px", fontWeight: "bold", color: "#333" }}>
          Date: {label}
        </p>
        {payload.map((item) => (
          <p key={item.dataKey} style={{ margin: 0, color: item.color }}>
            {item.name}: {item.value}{dataKey === "revenue" ? "€" : ""}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const MultiLineChart = ({ data, title, gyms, dataKey }) => {
  // Calculate the sum and average for each gym
  const calculateSumAndAverage = (gymName) => {
    const gymData = data.map((entry) => entry[gymName]);
    const sum = gymData.reduce((acc, value) => acc + value, 0);
    const average = sum / gymData.length;
    return { sum, average };
  };

  // Generate lines dynamically for each gym
  const lines = gyms.map((gym, index) => {
    const { sum, average } = calculateSumAndAverage(gym.nameGym);
    return (
      <Line
        key={gym.id}
        type="monotone"
        dataKey={gym.nameGym} // Gym's data key in the dataset
        name={gym.nameGym} // Label for the legend
        stroke={`hsl(${index * 50}, 70%, 50%)`} // Unique color for each gym
        strokeWidth={2}
        dot={true} // Disable dots for cleaner lines
      />
    );
  });

  // Custom legend formatter to display sum and average in the legend
  const renderLegend = (props) => {
    const { payload } = props;

    // Sort the payload by sum in descending order
    const sortedPayload = [...payload].sort((a, b) => {
      const aTotal = calculateSumAndAverage(a.value).sum;
      const bTotal = calculateSumAndAverage(b.value).sum;
      return bTotal - aTotal; // Sort from highest to lowest
    });

    return (
      <div className="flex justify-center gap-4 mt-[15px] flex-wrap">
        {sortedPayload.map((entry, index) => {
          const { sum, average } = calculateSumAndAverage(entry.value);
          return (
            <div key={index} className="flex items-center">
              <div
                style={{
                  backgroundColor: entry.color,
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  marginRight: "5px",
                }}
              ></div>
              <span style={{fontSize: "14px"}}>{entry.value}</span>
              <span style={{ marginLeft: "10px", fontSize: "13px", color: "gray" }}>
                Total: <span className="font-semibold">{sum.toFixed(2)} </span> | Moyenne: <span className="font-semibold">{average.toFixed(2)}</span>
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="p-6 bg-white border border-white rounded-xl shadow">
      <h3 className="text-lg font-semibold mb-4">{title}</h3>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          {/* XAxis */}
          <XAxis
            dataKey="date"
            tickLine={false}
            axisLine={false}
            tickFormatter={(value, index) =>
              index % 3 === 1 ? value : "" // Show ticks every 3 items
            }
          />
          {/* YAxis */}
          <YAxis
            tickLine={false}
            axisLine={false}
            tickFormatter={(value, index) => value}
          />
          {/* Tooltip */}
          <Tooltip content={<CustomTooltip dataKey={dataKey} />} />
          {/* Custom Legend */}
          <Legend content={renderLegend} />
          {/* Dynamic Lines */}
          {lines}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MultiLineChart;