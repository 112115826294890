import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import "../../styles/dashboard/dashboardPage.css";
import { Table, TableBody, TableCell, TableHeadCell, TableHeader, TableRow } from "../../utils/dashboard/table";
import { reload } from "../../store/actions/reload";
import { fetchAllMachinesData } from "../../store/actions/machine";
import { RotatingLines } from "react-loader-spinner";
import MenuBar from "src/utils/dashboard/menuBar";
import { FaDumbbell, FaRunning, FaBriefcase, FaBuilding } from 'react-icons/fa';
import Filter from "../../assets/images/filter_icon.jpg";
import { fetchGymData } from "src/store/actions/gyms";
import { getSizeStyle, getTypeStyle } from "src/utils/management/featureTableStyle";

const sizeOptions = ["<500", "<1000", "<1500", "<2000", "<2500", ">2500"];
const typeOptions = ["muscu", "crossfit", "office", "general"];


const MachinesPage = (props) => {
  const { reload } = props;
  const userId = props.userId;
  const [popoverData, setPopoverData] = useState(null);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const [isPopoverFilterVisible, setIsPopoverFilterVisible] = useState(false)
  const [sortConfig, setSortConfig] = useState({ key: "nameGym", direction: "ascending" }); // Sorting state
  const isPhone = window.innerWidth <= 768;
  const [sizeFilter, setSizeFilter] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const [filteredMachines, setFilteredMachines] = useState(props.allMachinesData || []);
  const [appliedFilters, setAppliedFilters] = useState(false); // Flag to apply filters

  useEffect(() => {
    reload(userId);
  }, [reload, userId]);

  // we need to fetch all the machine data and apply filters if they are some
  useEffect(() => {
    if (!props.allMachinesDataFetched) {
      props.fetchAllMachinesData();
    } else {
      setFilteredMachines(props.allMachinesData)
      // we launch the fetch of all the gyms data
      for (let machineData of props.allMachinesData) {
        if (!Object.keys(props.gymsData).includes(machineData.id)) {
          props.fetchGymData(machineData.id, machineData.name)
        }
      }
    }
  }, [props.allMachinesDataFetched]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      reload(userId);
    }, 60 * 1000);

    return () => clearInterval(intervalId);
  }, [userId, reload]);

  // Handle sorting logic
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  // this is done to save the filters selected
  const handleFilterChange = (filterType, selectedOption) => {
    if (filterType === "size") {
      // Only update to the last selected size
      setSizeFilter(selectedOption); // Directly set the selected size filter
    } else if (filterType === "type") {
      setTypeFilter([selectedOption]); // If only one type filter can be selected, use an array
    }
  };

  const clearFilters = () => {
    setSizeFilter([]);
    setTypeFilter([]);
    setAppliedFilters(false); // Reset the applied filters flag
    setIsPopoverFilterVisible(false); // Close the popover
    setFilteredMachines(props.allMachinesData); // Reset the data to the full set
  };

  // this is done to apply the filters on the data
  const applyFilters = () => {
    // Check if no filters are applied
    if (sizeFilter.length === 0 && typeFilter.length === 0) {
      // No filters applied, just sort by machineId
      const sortedMachines = [...props.allMachinesData].sort((a, b) => {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      });
  
      setFilteredMachines(sortedMachines); // Set sorted machines with no filters applied
      setAppliedFilters(false); // Mark that no filters are applied
    } else {
      // Apply filters if there are selected Filters      
      let filteredSizes = sizeFilter; // Start with the selected size
      
      // Add smaller sizes if the selected size contains "<"
      if (sizeFilter.length > 0 && sizeFilter[0].includes("<")) {
        const sizeValue = parseInt(sizeFilter[0].replace("<", ""));
        sizeOptions.forEach((sizeOption) => {
          if (sizeOption.includes("<")) {
            const size = parseInt(sizeOption.replace("<", ""));
            if (size < sizeValue) {
              filteredSizes.push(sizeOption);
            }
          }
        });
      }

      const newFilteredMachines = props.allMachinesData
        .filter((machineData) => {
          const matchesSize = filteredSizes.length === 0 || filteredSizes.includes(machineData.size);
          const matchesType = typeFilter.length === 0 || typeFilter.includes(machineData.type);

          return matchesSize && matchesType;
        })
        .sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        }); // Sort filtered data by machineId
      
      setFilteredMachines(newFilteredMachines);
      setAppliedFilters(true); // Mark that filters are applied
    }
  
    setIsPopoverFilterVisible(false); // Close the popover
  };


  // this is to change the order of the gyms displays
  const renderSortIndicator = (key) => {
    const isActive = sortConfig.key === key;
    const ascending = isActive && sortConfig.direction === "ascending";
    const descending = isActive && sortConfig.direction === "descending";

    return (
      <span className="ml-2 text-gray-500">
        <span className={`${descending ? "text-black" : ""}`}>↑</span>
        <span className={`${ascending ? "text-black" : ""}`}>↓</span>
      </span>
    );
  };

   // this is the pop over to select the different filters to apply
   const PopoverContentFilter = () => (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30"
      onClick={() => setIsPopoverFilterVisible(false)}
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6 relative"
        style={{
          width: "min(90%, 550px)",
          maxHeight: "50vh",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Popover Content */}
        <div className="flex items-start">
          <button onClick={() => setIsPopoverFilterVisible(false)} className="text mr-4">
            ✕
          </button>
          <h3 className="text-center flex-grow font-semibold -ml-[14px]">Filtres</h3>
        </div>
        <hr className="mt-[20px] border-gray-300" />
  
        <div className="overflow-y-auto">
        {/* Selected Filters Section */}
        {sizeFilter.length > 0 || typeFilter.length > 0 ? (
          <div>
            <h4 className="font-semibold mt-4 mb-[15px]">Filtres sélectionnés</h4>
            <div className="flex flex-wrap gap-2 mt-2">
            {sizeFilter.length > 0 && (
              <div
                className="flex items-center px-5 py-3 border border-orange-500 rounded-full bg-orange-100"
              >
                <span className="font-semibold mr-2" style={{ fontSize: "14px" }}>{sizeFilter}</span>
                <button
                  className="text-gray-500 hover:text-red-500"
                  style={{ fontSize: "14px" }}
                  onClick={() => setSizeFilter("")} // Clear the size filter
                >
                  ✕
                </button>
              </div>
            )}
            {typeFilter.map((filter, idx) => (
              <div
                key={idx}
                className="flex items-center px-5 py-3 border border-orange-500 rounded-full bg-orange-100"
              >
                <span className="font-semibold mr-2" style={{ fontSize: "14px" }}>{filter}</span>
                <button
                  className="text-gray-500 hover:text-red-500"
                  style={{ fontSize: "14px" }}
                  onClick={() => {
                    setTypeFilter(typeFilter.filter((f) => f !== filter));  // Remove selected type filter
                  }}
                >
                  ✕
                </button>
              </div>
            ))}
            </div>
            <hr className="mt-[20px] border-gray-300" />
          </div>
        ) : null}
  
        {/* Size Filters */}
        <h4 className="font-semibold mt-4 text-left mb-[15px]">Taille de salle</h4>
        <div className="flex flex-row flex-wrap gap-2 mt-2">
          {sizeOptions.map((option) => (
            <button
              key={option}
              onClick={() => handleFilterChange("size", [option])}
              className={`text-left px-5 py-3 ${sizeFilter.includes(option) ? "bg-orange-100 border-orange-500" : ""} border rounded-full`}
              style={{ fontSize: "14px" }}
            >
              {option}
            </button>
          ))}
        </div>
        <hr className="mt-[25px] border-gray-300" />
  
        {/* Type Filters */}
        <h4 className="font-semibold mt-[20px] text-left mb-[15px]">Type de salle</h4>
        <div className="flex flex-row flex-wrap gap-2 mt-2 mb-[80px]">
          {typeOptions.map((option) => {
            let icon = null;
  
            switch (option) {
              case "muscu":
                icon = <FaDumbbell className="text-lg mr-2" />;
                break;
              case "crossfit":
                icon = <FaRunning className="text-lg mr-2" />;
                break;
              case "office":
                icon = <FaBriefcase className="text-lg mr-2" />;
                break;
              case "general":
                icon = <FaBuilding className="text-lg mr-2" />;
                break;
              default:
                break;
            }
  
            return (
              <button
                key={option}
                onClick={() => handleFilterChange("type", option)}
                className={`text-left px-5 py-3 ${typeFilter.includes(option) ? "bg-orange-100 border-orange-500" : ""} border flex rounded-full`}
                style={{ fontSize: "14px" }}
              >
                {icon}
                {option}
              </button>
            );
          })}
        </div>
        
        </div>
        {/* Footer with Clear and Apply Buttons */}
        <div 
        className="absolute bottom-0 left-0 w-full bg-white border-t border-gray-300 shadow-md px-6 py-4 flex justify-between items-center"
        style={{
          boxShadow: "0 -3px 6px rgba(0, 0, 0, 0.2)", // Adds a subtle shadow on the top border of the footer
          borderBottomLeftRadius: "0.5rem", 
          borderBottomRightRadius: "0.5rem"
        }}
        >

          <button
            onClick={clearFilters}
            className="text-black hover:text-red-500"
            style={{fontWeight: 500}}
          >
            Tout effacer
          </button>
          <button
            onClick={applyFilters}
            className="text-white bg-orange-500 px-5 py-2.5"
            style={{fontWeight: 600, borderRadius: "0.5rem"}}
          >
            Afficher les salles
          </button>
        </div>
      </div>
    </div>
  );

  // this is the pop over to display the information of the machine selected
  const PopoverContent = () => (
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-30"
      onClick={closePopover}
    >
      <div
        className="bg-white rounded-lg shadow-lg p-6"
        style={{
          width: "min(70%, 700px)",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
        onClick={(e) => e.stopPropagation()} // Prevent click from closing the popover
      >
        <p className="mb-2">
          <span className="font-semibold text-left">Nom de salle:</span> {popoverData.nameGym}
        </p>
        <p className="mb-2">
          <span className="font-semibold text-left">Machine ID:</span> {popoverData.id}
        </p>
        <p className="mb-4">
          <span className="font-semibold">Gérant:</span> {popoverData.nameOwner}
        </p>
        <p className="mb-4">
          <span className="font-semibold">Adresse:</span> {popoverData.addressGym}
        </p>

        {popoverData?.complaints?.length > 0 && (
        <>
          <p className="mb-2">
            <span className="font-semibold">Plaintes</span>
          </p>
          <div className="mt-2 border border-gray-300 rounded-md">
            <Table className="w-full text-sm border border-gray-300 rounded-md overflow-hidden">
              <TableHeader className="bg-white border-b border-gray-300">
                <TableRow className="hover:bg-transparent">
                  <TableHeadCell className="px-4 py-2 text-center">Date</TableHeadCell>
                  <TableHeadCell className="px-4 py-2 text-center">Détails</TableHeadCell>
                  <TableHeadCell className="px-4 py-2 text-center">Dernière boisson</TableHeadCell>
                </TableRow>
              </TableHeader>
              <TableBody>
                {popoverData.complaints.map((complaint, index) => (
                  <TableRow key={index} className="hover:bg-gray-100">
                    <TableCell className="px-4 py-2 text-center">{complaint.date}</TableCell>
                    <TableCell className="px-4 py-2 text-center">{complaint.complaintContent}</TableCell>
                    <TableCell className="px-4 py-2 text-center">{complaint.lastDrinkMachine}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </>
        )}

      </div>
    </div>
  );

  // this opens the pop over for the machine data
  const handlePopover = (event, machine) => {
    event.stopPropagation();
    setPopoverData(machine);
    setIsPopoverVisible((prev) => (popoverData?.id === machine.id ? !prev : true));
  };

  const closePopover = () => {
    setIsPopoverVisible(false);
  };
  
  const menuItems = [
    { name: "Distributeurs", route: "/management/machines" },
    { name: "Plaintes", route: "/management/plaintes" },
  ];

  if (props.allMachinesDataFetched) {
    return (
      <div
        className="flex flex-col h-screen w-full overflow-hidden p-6"
        style={{ paddingLeft: "4%", paddingRight: "4%", marginTop: "20px", marginBottom: "20px" }}
      >
        <div className="flex-grow overflow-auto w-full p-8">
          <MenuBar menuItems={menuItems}/>
          {/* Filters Section */}
          <div className="p-4 flex-grow overflow-auto bg-white border border-transparent rounded-xl shadow" style={{ marginBottom: "20px" }}>
          {/* Filters Button */}
            <button
            onClick={() => setIsPopoverFilterVisible(true)}
            className="flex items-center justify-between px-4 py-2 border border-black rounded-xl text-left whitespace-nowrap hover:bg-transparent relative"
            style={{
              borderColor: appliedFilters ? "orange" : "hsl(214.3 31.8% 91.4%)",
              borderWidth: appliedFilters > 0 ? 2 : 1
            }}
            >
              <img
                alt=""
                src={Filter}  // Make sure to replace this with the correct path to your image
                // alt="Filter Icon"
                className="mr-2"  // Adjust the margin as needed
                style={{ width: "20px", height: "auto" }}  // Adjust the size of the icon
              />
              Filtres
              { appliedFilters && 
              <span
                className={`absolute top-[-8px] right-[-8px] flex items-center justify-center w-5 h-5 text-xs font-semibold text-white ${sizeFilter.length || typeFilter.length ? "bg-orange-500" : "bg-transparent"}`}
                style={{
                  borderRadius: "50%",  // Make it a circle
                  padding: "2px", // Padding for better alignment
                }}
              >
                {(
                  (sizeFilter.length > 0 ? 1 : 0) + // Count size filter as 1 if selected
                  typeFilter.length // Count number of type filters selected
                ) > 0 ? `${(sizeFilter.length > 0 ? 1 : 0) + typeFilter.length}` : ""}
              </span>
              }
            </button>
            <Table className="w-full">
              <TableHeader>
                <TableRow className="hover:bg-transparent">
                  <TableHeadCell className="w-[30px] text-center text-sm text-black">Active</TableHeadCell>
                  <TableHeadCell className="flex-1 px-4 py-4 text-center text-sm text-black">Machine ID</TableHeadCell>
                  {!isPhone && (
                  <TableHeadCell
                    className="flex-1 cursor-pointer px-4 py-4 text-center text-sm text-black"
                    onClick={() => handleSort("nameGym")}
                  >
                  
                    Nom salle {renderSortIndicator("nameGym")}
                  </TableHeadCell>
                  )}
                  {!isPhone && (
                    <TableHeadCell className="flex-1 px-4 py-4 text-center text-sm text-black">Adresse</TableHeadCell>
                  )}
                  {!isPhone && (
                  <TableHeadCell className="w-[120px] text-center text-sm text-black">Taille</TableHeadCell>
                  )}
                  {!isPhone && (
                  <TableHeadCell className="w-[120px] text-center text-sm text-black">Type</TableHeadCell>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredMachines.map((machineData) => (
                  <TableRow className="table-row-hover" key={machineData.id} onClick={(e) => handlePopover(e, machineData)}>
                    <TableCell className="w-[30px] justify-center items-center text-sm text-gray-600" machineDasboard={true}>
                      {machineData.isActive ? (
                        <div className="flex-1 w-5 h-5 ml-2.5 items-center justify-center rounded-full bg-green-500 text-white">
                          ✔
                        </div>
                      ) : (
                        <div className="flex-1 w-5 h-5 ml-2.5 items-center justify-center rounded-full bg-red-500 text-white">
                          ✖
                        </div>
                      )}
                    </TableCell>
                    <TableCell machineDasboard={true}>{machineData.id}</TableCell>
                    {!isPhone && <TableCell machineDasboard={true}>{machineData.nameGym}</TableCell>}
                    {!isPhone && <TableCell machineDasboard={true}>{machineData.addressGym}</TableCell>}
                    {!isPhone && 
                    <TableCell className="w-[120px] justify-center items-center">
                      <span style={getSizeStyle(machineData.size)}>{machineData.size}</span> {/* Wrap size in a span */}
                    </TableCell>
                    }
                    {!isPhone && 
                    <TableCell className="w-[120px] justify-center items-center">
                      <span style={getTypeStyle(machineData.type)}>{machineData.type}</span> {/* Wrap type in a span */}
                    </TableCell>
                    }
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
        {isPopoverVisible && popoverData && <PopoverContent />}
        {isPopoverFilterVisible && <PopoverContentFilter />}
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen space-y-6">
        <RotatingLines strokeColor="#f08300" height={30} width={30} visible={true} animationDuration="1.25" />
      </div>
    );
  }
};


const mapStateToProps = (state) => ({
  userId: state.userState.userId,
  allMachinesData: state.machineState.allMachinesData,
  allMachinesDataFetched: state.machineState.allMachinesDataFetched,
  gymsData: state.gymState.gymsData,
});

const mapDispatchProps = (dispatch) => {
  return {
    reload: (userId) => {dispatch(reload(userId))},
    fetchAllMachinesData: () => {dispatch(fetchAllMachinesData())},
    fetchGymData: (machineId, nameGym) => dispatch(fetchGymData(machineId, nameGym)),
  };
};

export default connect(mapStateToProps, mapDispatchProps)(MachinesPage);
