import axios from "axios";
const axiosRetry = require('axios-retry').default;

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

const _BASE_URL = "https://server.fiilin-app-server.com:" // server

export const postRequest = async(path, data, port) => {
  const token = await loginAndGetToken(5000)

  return axios.post(`${_BASE_URL}${port}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
      "x-auth-token": token
    },
    timeout: 10000,
  });
};

export const getRequest = async(path, data, port) => {
  const token = await loginAndGetToken(5000)
    return axios.get(`${_BASE_URL}${port}${path}`, {
        params: data,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": token
        },
        timeout: 10000,
    });
};


export const loginAndGetToken = async(port) => {
    console.log(process.env)
    try {
        console.log(port)
        let response = await fetch(`https://server.fiilin-app-server.com:${port}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                username: process.env.REACT_APP_USER_NAME,
                password: process.env.REACT_APP_USER_PASSWORD
            }),
        });
        let responseJson = await response.json();
        return responseJson.token;
    } catch (error) {
        console.log("errorrr")
        console.log('Error logging in:', error);
    }
}