import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DataBlock from "../../utils/dashboard/dataBlock";
import HistogramBar from "src/utils/dashboard/histogramBar";
import DonutChart from "src/utils/dashboard/donutChart";
import CustomSelect from "./customSelect";
import { RotatingLines } from "react-loader-spinner";
import { PlaceholderBlock, PlaceholderGraph } from "src/utils/placeholder";
import DatePicker from "react-datepicker";
import { fr } from "date-fns/locale"; // Import French locale
import "react-datepicker/dist/react-datepicker.css";
import { subMonths, startOfYear, endOfYear } from "date-fns";
import "../../styles/dashboard/customDatePicker.css"; // Import custom CSS file for DatePicker customization
import {
  fetchGymData,
  resetMachineIdGym,
  resetMGymName,
  setGymDataReady,
  setGymName,
  setMachineIdGym,
} from "src/store/actions/gyms";
import { fetchAllMachinesData } from "src/store/actions/machine";
import StackedAreaChart from "src/utils/dashboard/stackedAreaChart";
import AreaGraph from "src/utils/dashboard/areaGraph";
import LastCustomersBox from "src/utils/dashboard/lastCustomerBox";
import ProductTable from "src/utils/dashboard/productTable";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useLocation } from "react-router-dom";

const Dashboard = (props) => {
  const isPhone = window.innerWidth <= 768;

  const [isLoadingGymData, setIsLoadingGymData] = useState(true);
  const [repartitionDrinks, setRepartitionDrinks] = useState([]);
  const currentDate = new Date();
  const currentStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
  const [dateRange, setDateRange] = useState([
    currentStartDate, // First day of the current month
    null, // End date will be null for single-month selection
  ]);
  const [startDate, endDate] = dateRange;
  const [selectedMonthId, setSelectedMonthId] = useState(`${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}`)
  const location = useLocation();
  const currentMonthId = `${currentStartDate.getFullYear()}-${String(currentStartDate.getMonth() + 1).padStart(2, '0')}`
  const [selectedDateOption, setSelectedDateOption] = useState(null)

  
  useEffect(() => {
    // Update selected month ID based on startDate
    if (startDate) {
      const selectedMonth = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}`;
      setSelectedMonthId(selectedMonth);
    }
  }, [startDate]);

  // we fetch the data of all gyms but not the financial of the membership data, just the primary data of gyms
  // if the data is already fetched, then, we set a machine id as a default one along with the name, which is the 
  // the first gym by alphabetic order
  useEffect(() => {
    if (!props.allMachinesDataFetched) {
      props.fetchAllMachinesData();
    } else if (Object.keys(props.gymsData).length === 0 && props.allMachinesData.length > 0) {
      props.setMachineIdGym(props.allMachinesData[0].id);
      props.setGymName(props.allMachinesData[0].name)
    }
  }, [props.allMachinesDataFetched, props.gymsData]);


  useEffect(() => {
    // Check if the current path matches the finance dashboard one
    if (location.pathname.startsWith("/finance")) {
      // Here we check if a machine id was already saved before and thus we display the corresponding data and not 
      // necessary the first gym of the list 
      if (props.allMachinesData) {
        if (!props.machineIdGym && props.allMachinesData.length > 0) {
          props.setMachineIdGym(props.allMachinesData[0].id);
          props.setGymName(props.allMachinesData[0].name)
        } else if (props.allMachinesData.length > 0) {
          const selectedGymName = props.allMachinesData.filter(
            (gym) => gym.id === props.machineIdGym
          );
          if (selectedGymName.length > 0) {
            props.setGymName(selectedGymName[0].name)
          }
        }
      } else {
        props.fetchAllMachinesData();
      }
    }
  }, [location.pathname, props.machineIdGym, props.allMachinesData]);


  // this function is to handle the case where we click on a specific gym
  const handleSelect = (machineId, nameGym) => {
    // we show a spinner is the data has not been fetched yet
    setIsLoadingGymData(true);

    // to display the data of the gym we need to finance information and the memberships information
    if (Object.keys(props.gymsData).includes(machineId)) {
      props.setGymDataReady();
    }
    else {
      // we fetch the info
      props.fetchGymData(machineId, nameGym);
    }

    // we then update the machine id and the gymName because if we do it earlier then the useEffect line 109
    // is going to trigger and we want it only when the check for the data fetching has been done 
    props.setMachineIdGym(machineId);
    props.setGymName(nameGym)
  };

  useEffect(() => {
    // if a machine id has been set but the gym data was not fetched yet, then we fetch it
    if (props.setMachineIdGymSuccess && !Object.keys(props.gymsData).includes(props.machineIdGym)) {
      props.fetchGymData(props.machineIdGym, props.gymName);
    }
  }, [props.setMachineIdGymSuccess, props.machineIdGym]);


  // this is done to create the repartitionDrinks variable to display the distribution between the 
  // different type of drinks
  useEffect(() => {
    if (
      props.gymDataFetched &&
      props.machineIdGym &&
      props.gymsData.hasOwnProperty(props.machineIdGym) && // Check if machineIdGym is a valid key in gymsData
      props.gymsData[props.machineIdGym][selectedMonthId]
    ) {
      const drinksRepartition = [
        { name: "Gratuites", value: props.gymsData[props.machineIdGym][selectedMonthId]["finance"]["freeDrinks"] },
        { name: "Payantes", value: props.gymsData[props.machineIdGym][selectedMonthId]["finance"]["paidDrinks"] },
        { name: "Abonnements", value: props.gymsData[props.machineIdGym][selectedMonthId]["finance"]["membershipDrinks"] },
        { name: "Packs", value: props.gymsData[props.machineIdGym][selectedMonthId]["finance"]["packDrinks"] },
      ];

      setRepartitionDrinks(drinksRepartition);
      props.resetMachineIdGym();
      setIsLoadingGymData(false);
    }
  }, [props.gymDataFetched, props.machineIdGym, props.gymsData, props.fetchedGymsFinanceNumber, props.fetchedGymsMembershipNumber, selectedMonthId]);


  // this function is to highlight the month selected on the calendar date picker
  const highlightMonth = (date) => {
    if (!startDate || !endDate) return "";

    const isStartOrEnd =
      (startDate?.getFullYear() === date.getFullYear() && startDate?.getMonth() === date.getMonth()) ||
      (endDate?.getFullYear() === date.getFullYear() && endDate?.getMonth() === date.getMonth());

    const isBetween =
      startDate &&
      endDate &&
      date > startDate &&
      date < endDate;

    if (isStartOrEnd) return "selected-month";
    if (isBetween) return "between-month";

    return "";
  };

  const handleQuickSelect = (option) => {
    const today = new Date();

    switch (option) {
      case "lastMonth":
        setDateRange([subMonths(today, 1), today]);
        setSelectedDateOption("lastMonth")
        break;
      case "last3Months":
        setDateRange([subMonths(today, 3), today]);
        setSelectedDateOption("last3Months")
        break;
      case "currentYear":
        setDateRange([startOfYear(today), endOfYear(today)]);
        setSelectedDateOption("currentYear")
        break;
      case "selectionYear":
        setDateRange([null, null]);
        setSelectedDateOption("selectionYear")
        break;
      case "selectionMonth":
        setDateRange([null, null]);
        setSelectedDateOption("selectionMonth")
        break;
      default:
        setSelectedDateOption(null)
        setDateRange([null, null]);
        break;
    }
  };

  
  const customCalendarContainer = ({ children }) => (
    <div
    className="border border-transparent rounded-xl"
      style={{
        display: "flex",
        alignItems: "stretch",
        marginRight: `calc(4vw + (8 / 100) * (92vw))`, // Dynamic calculation for marginRight
      }}
    >
      {/* Sidebar */}
      <div className="p-4 bg-white border-r flex flex-col justify-between border border-transparent" style={{borderBottomLeftRadius: "10px", borderTopLeftRadius: "10px"}}>
        <div>
          {/* <h4 className="font-semibold mb-4">Options rapides</h4> */}
          <button
            className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
              selectedDateOption === "lastMonth"
                ? "bg-customOrange-dark text-white"
                : "bg-white text-black hover:bg-customOrange-light"
            }`}
            onClick={() => handleQuickSelect("lastMonth")}
          >
            Dernier mois
          </button>
          <button
            className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
              selectedDateOption === "last3Months"
                ? "bg-customOrange-dark text-white"
                : "bg-white text-black hover:bg-customOrange-light"
            }`}
            onClick={() => handleQuickSelect("last3Months")}
          >
            3 derniers mois
          </button>
          <button
            className={`block w-full mb-2 py-4 px-2 border border-transparent rounded-xl ${
              selectedDateOption === "currentYear"
                ? "bg-customOrange-dark text-white"
                : "bg-white text-black hover:bg-customOrange-light"
            }`}
            onClick={() => handleQuickSelect("currentYear")}
          >
            Année en cours
          </button>
          <button
            className="block w-full mb-2 py-4 px-2 bg-white border border-transparent rounded-xl hover:bg-customOrange-light"
            onClick={() => setDateRange([null, null])}
          >
            Réinitialiser
          </button>
        </div>
      </div>

      {/* Calendar */}
      <div className="bg-white" style={{ flexGrow: 1, padding: "10px", borderTopRightRadius: "10px", borderBottomRightRadius: "10px"}}>{children}</div>
    </div>
  );

  return (
    <div className="min-h-screen flex flex-col w-full p-6 bg-customOrange-light" style={{ marginTop: "20px", paddingLeft: "4%", paddingRight: "4%" }}>
      {/* Gym Selector and Date Range Picker */}
      {props.allMachinesDataFetched && (
        <div className="w-full p-8">
          <div className="flex items-center justify-between mt-[20px] mb-[20px]">
            {/* Gym Selector */}
            <CustomSelect machines={props.allMachinesData} onSelect={handleSelect}/>
            {/* Date Picker */}
            <div className="flex flex-row ml-4 items-center justify-between bg-white border border-transparent rounded-xl px-4">
              {/* <p className="text-gray-600 mb-1 font-semibold">Select Period</p> */}
              <FaRegCalendarAlt />
              <DatePicker
                selectsRange
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => setDateRange(update)}
                dateFormat="MMM yyyy"
                showMonthYearPicker
                placeholderText={startDate}
                className="custom-datepicker-input" // Add custom class
                calendarClassName="custom-datepicker-calendar" // Add custom calendar class
                dayClassName={(date) => highlightMonth(date)}
                locale={fr} // Set the locale to French
                calendarContainer={customCalendarContainer}
              />
            </div>
          </div>
          {/* <hr className="w-[calc(100%+64px)] -ml-8 border-t border-gray-300 my-4" /> */}
          {/* Content */}
          {props.machineIdGym && props.gymsData.hasOwnProperty(props.machineIdGym) && props.gymsData[props.machineIdGym] && props.gymsData[props.machineIdGym] ? (
            <div className="flex flex-col flex-grow space-y-4">
              <div className={`grid gap-4 w-full ${isPhone ? "grid-cols-2" : "grid-cols-4"}`}>
                <DataBlock title="Revenu total" value={`${(props.gymsData[props.machineIdGym][selectedMonthId]["finance"].revenue + props.gymsData[props.machineIdGym][selectedMonthId]["memberships"].moneyMemberships).toFixed(2)}`} type="money" />
                <DataBlock title="Nombre boissons" value={props.gymsData[props.machineIdGym][selectedMonthId]["finance"].totalDrinks} type="drink" />
                <DataBlock title="Revenus unité" value={`${props.gymsData[props.machineIdGym][selectedMonthId]["finance"].revenue.toFixed(2)}`} type="money" />
                <DataBlock title="Revenu abonnement" value={`${props.gymsData[props.machineIdGym][selectedMonthId]["memberships"].moneyMemberships.toFixed(2)}`} type="subscription" />
              </div>
              {isPhone ? (
                <div className="flex flex-col gap-4 w-full">
                  <div className="h-full">
                    <AreaGraph
                      data={props.gymsData[props.machineIdGym][selectedMonthId]["finance"].dailyRevenue}
                      title="Evolution du revenu boisson"
                      dataKey="revenue"
                      revenue={true}
                      nameTooltip="Revenu"
                      colorLine="#001B25"
                      colorArea="#90CAF9"
                    />
                  </div>
                  <div className="h-full">
                    <HistogramBar data={repartitionDrinks} title="Répartition des boissons" dataKey="value" />
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-5 gap-4 w-full flex-grow">
                  <div className="col-span-3 h-full">
                  <AreaGraph
                      data={props.gymsData[props.machineIdGym][selectedMonthId]["finance"].dailyRevenue}
                      title="Evolution du revenu boisson"
                      dataKey="revenue"
                      revenue={true}
                      nameTooltip="Revenu"
                      colorLine="#001B25"
                      colorArea="#90CAF9"
                    />
                  </div>
                  {/* Last Customers Box */}
                  <div className="col-span-2 h-full">
                    <HistogramBar data={repartitionDrinks} title="Répartition des boissons" dataKey="value" />
                  </div>
                </div>
              )}
              {/* Line Graphs */}
              {isPhone ? (
                <div className="flex flex-col gap-4 w-full">
                  {
                    props.gymsData[props.machineIdGym][selectedMonthId]["memberships"].totalMemberships > 0 && 
                    <div className="h-full">
                      <DonutChart data={props.gymsData[props.machineIdGym][selectedMonthId]["memberships"]} title="Répartition des abonnements" needTransform={true}/>
                    </div>
                  }
                  <div className="h-full">
                    <AreaGraph
                        data={props.gymsData[props.machineIdGym][selectedMonthId]["finance"].dailyRevenue}
                        title="Evolution du revenu boissons"
                        dataKey="revenue"
                        revenue={true}
                        nameTooltip="revenu"
                        colorLine="#001B25"
                        colorArea="#90CAF9"
                      />
                  </div>
                </div>
              ) : (
                <div>
                  {
                    props.gymsData[props.machineIdGym][selectedMonthId]["memberships"].totalMemberships > 0 ? 
                    <div className="grid grid-cols-5 gap-4 w-full flex-grow">
                      <div className="col-span-2 h-full">
                        <DonutChart data={props.gymsData[props.machineIdGym][selectedMonthId]["memberships"]} title="Répartition des abonnements" needTransform={true}/>
                      </div>
                      <div className="col-span-3 h-full">
                        <AreaGraph
                          data={props.gymsData[props.machineIdGym][selectedMonthId]["finance"].dailyDrinks}
                          title="Evolution du nombre de boissons"
                          dataKey="drinks"
                          nameTooltip="Nb boissons"
                          colorLine="#F08300"
                          colorArea="#FFD699"
                        />
                      </div>
                    </div> : 
                    <div className="w-full flex-grow h-full">
                      <AreaGraph
                        data={props.gymsData[props.machineIdGym][selectedMonthId]["finance"].dailyDrinks}
                        title="Evolution du nombre de boissons"
                        dataKey="drinks"
                        nameTooltip="Nb boissons"
                        colorLine="#F08300"
                        colorArea="#FFD699"
                      />
                    </div>
                  }
                </div>
              )}
              {/* Line Graphs */}
              {isPhone ? (
                <div className="flex flex-col gap-4 w-full">
                  <div className="h-full">
                    <StackedAreaChart data={props.gymsData[props.machineIdGym][selectedMonthId]["finance"].sportAndProtein} />
                  </div>
                  <div className="h-full">
                    <LastCustomersBox customers={props.gymsData[props.machineIdGym][currentMonthId]["finance"].bestCustomers} />
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-5 gap-4 w-full flex-grow">
                  <div className="col-span-3 h-full">
                    <StackedAreaChart data={props.gymsData[props.machineIdGym][selectedMonthId]["finance"].sportAndProtein} />
                  </div>
                  {/* Last Customers Box */}
                  <div className="col-span-2 h-full">
                    <LastCustomersBox customers={props.gymsData[props.machineIdGym][currentMonthId]["finance"].bestCustomers} />
                  </div>
                </div>
              )}
              {/* Product Table */}
              <div className="h-full w-full mb-8">
                <ProductTable products={props.gymsData[props.machineIdGym][selectedMonthId]["finance"].productData} />
              </div>
            </div>
          ) : (
            <div className="flex flex-col flex-grow space-y-6">
              <RotatingLines strokeColor="#f08300" height={30} width={30} visible={true} animationDuration="1.25" />
              <div className="grid grid-cols-4 gap-4 w-full">
                <PlaceholderBlock title="Chiffre d'affaire" />
                <PlaceholderBlock title="Nombre boissons" />
                <PlaceholderBlock title="Revenu abonnement" />
                <PlaceholderBlock title="Utilisateurs" />
              </div>
              <div className="grid grid-cols-2 gap-3 w-full flex-grow">
                <PlaceholderGraph />
                <PlaceholderGraph />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userId: state.userState.userId,
  gymsData: state.gymState.gymsData,
  gymDataFetched: state.gymState.gymDataFetched,
  allMachinesData: state.machineState.allMachinesData,
  allMachinesDataFetched: state.machineState.allMachinesDataFetched,
  setMachineIdGymSuccess: state.gymState.setMachineIdGymSuccess,
  machineIdGym: state.gymState.machineIdGym,
  gymName: state.gymState.gymName, 
  fetchedGymsFinanceNumber: state.gymState.fetchedGymsFinanceNumber, 
  fetchedGymsMembershipNumber: state.gymState.fetchedGymsMembershipNumber
});

const mapDispatchProps = (dispatch) => ({
  fetchGymData: (machineId, nameGym) => dispatch(fetchGymData(machineId, nameGym)),
  fetchAllMachinesData: () => dispatch(fetchAllMachinesData()),
  setMachineIdGym: (machineId) => dispatch(setMachineIdGym(machineId)),
  setGymDataReady: () => dispatch(setGymDataReady()),
  resetMachineIdGym: () => dispatch(resetMachineIdGym()),
  setGymName: (gymName) => dispatch(setGymName(gymName)), 
  resetMGymName: () => dispatch(resetMGymName()),
});

export default connect(mapStateToProps, mapDispatchProps)(Dashboard);
